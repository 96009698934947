import { connectSocket } from "@/socket";
import { Socket } from "socket.io-client";
import { toRaw } from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { TicketsRoutesEnum } from "@/modules/tickets/router";
import LiveViewFilters from "@/modules/live-view/pages/LiveView/LiveViewFilters.vue";
import {usersService} from "@services/users.service";
import {LiveViewRoutesEnum} from "@/modules/live-view/router";
import {TicketStatusEnum} from "@/model/enums/TicketStatusEnum";
import {getDarkColor} from "@/utils/utils";
import {CompanyTypeEnum} from "@/model/enums/CompanyTypeEnum";
import {authStore} from "@/modules/auth/store";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {User} from "@/model/api/User";
import {Company} from "@/model/api/Company";
import {Shift} from "@/model/api/Shift";
import {shiftsService} from "@services/shifts.service";
import {companiesService} from "@services/companies.service";
import {ShiftsRoutesEnum} from "@/modules/shifts/router";

@Options({
  components: {
    LiveViewFilters
  }
})
export default class LiveViewPage extends Vue {
  @Prop() readonly propName!: String;

  socket: Socket = null;

  advFilters: any = {};

  trucksOnMap: any[] = [];

  drivers: User[] = []
  brokers: Company[] = []
  wrs: Shift[] = []

  mapRef: google.maps.Map = null;

  wrsColorsMap = {}
  statusIconMap

  get map() {
    return toRaw(this.mapRef);
  }

  onClearFilter(){
    this.initFilters()
    this.$router.replace({name: LiveViewRoutesEnum.PREFIX})
    this.onFiltersChange()
  }

  get isBroker() {
    return authStore.getters.me.role == UserRoleEnum.BROKER;
  }

  initFilters(){
    this.advFilters = {
      user: null,
      shift: null,
      status: null,
      broker: this.isBroker ? {id: authStore.getters.me.broker_id } : null,
    }
  }

  private _buildMap(element: HTMLElement, bounds?: google.maps.LatLngBounds) {

    this.mapRef = new google.maps.Map(
      element,
      {
        center: this.$config.startCenter,
        zoom: this.$config.startZoom,
      },

    );

    if (bounds) {
      this.map.fitBounds(bounds, 0);
    }

    this.map.data.setStyle({
      strokeWeight: 1,
      strokeOpacity: 1,
      strokeColor: "#3399FF",
      fillColor: "#3399FF",
      fillOpacity: 0.2,
      editable: false,
      draggable: false,
      clickable: true,
      zIndex: 1,
    });
  }

  public updateBounds() {
    if(this.trucksOnMap.length){
      const bounds = new google.maps.LatLngBounds();

      this.trucksOnMap.forEach(({ marker }) => {
        if(marker.getMap()){
          bounds.extend(marker.getPosition());
        }
      });

      this.map.fitBounds(bounds, 100);
    }
  }

  get hasMarkers(){
    return this.trucksOnMap.length
  }

  onFiltersChange(){
    this.trucksOnMap.forEach((truck) => {
      if(!this.canTruckBeVisible(truck)){
        toRaw(truck.marker).setMap(null)
      } else {
        toRaw(truck.marker).setMap(this.map)
      }
    })
    this.updateBounds()
  }

  canTruckBeVisible(data){

    const filters: any = {
      status: this.advFilters.status
    }

    // Filtro per Utente
    filters.id = this.advFilters?.user?.id;

    // Filtro per Shift
    filters.shift_id = this.advFilters?.shift?.id;

    // Filtro per Broker
    filters.broker_id = this.advFilters?.broker?.id;

    let match: boolean = true
    for (const property in filters) {
      if(filters[property]){
        match = data[property] === filters[property]
      }
      if(!match){
        return false
      }
    }
    return match;
  }

  private async updateTrucks(msg) {

    const wasEmpty = !this.trucksOnMap?.length;
    const truckId = msg.id;

    const truck = this.trucksOnMap?.find(t => t.id === truckId);

    const position = new google.maps.LatLng({
      lat: msg.data.lat, lng: msg.data.lng
    });

    const currentIcon = (this.statusIconMap[msg.data.status] || this.$refs.truck_other) as HTMLElement

    if (!truck) {
      if(!this.wrsColorsMap[msg.data.shift_id]){
        let randomColor
        const assignColor = () => {
          randomColor = getDarkColor()
          for (const [key, value] of Object.entries(this.wrsColorsMap)) {
            if(value === randomColor){
              assignColor()
              return
            }
          }
        }
        assignColor()
        this.wrsColorsMap[msg.data.shift_id] = randomColor
      }

      const marker = new google.maps.Marker({
        position,
        map: this.map,
        icon: {
          url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(
              currentIcon.outerHTML.replaceAll('{{background}}', this.wrsColorsMap[msg.data.shift_id])
          ),
          scaledSize: new google.maps.Size(50, 50), // scaled size
        },
      });

      marker.addListener('click', () => {
        let routeData
        if(authStore.getters.me.role === UserRoleEnum.FOREMAN){
          routeData = this.$router.resolve({
            name: ShiftsRoutesEnum.SHIFTS_DETAIL,
            params: { shiftId: msg.data.shift_id }
          });
        } else {
          routeData = this.$router.resolve({
            name: TicketsRoutesEnum.TICKETS_DETAIL,
            params: { ticketId: msg.data.ticket_id }
          });
        }

        window.open(routeData.href, '_blank');
      });

      const currentTruck = {
        id: truckId,
        name: `Truck_${truckId}`,
        status: msg.data.status,
        broker_id: msg.data.broker_id,
        shift_id: msg.data.shift_id,
        createdAt: msg.createdAt,
        lat: msg.data.lat,
        lng: msg.data.lng,
        marker,
      }

      if(!this.canTruckBeVisible(currentTruck)){
        toRaw(currentTruck.marker).setMap(null)
      }
      this.trucksOnMap.push(currentTruck);
    } else {
      if(msg.data.status !== truck.status) {
        truck.status = msg.data.status
        toRaw(truck.marker).setIcon({
          url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(
              currentIcon.outerHTML.replaceAll('{{background}}', this.wrsColorsMap[msg.data.shift_id]),
          ),
          scaledSize: new google.maps.Size(50, 50), // scaled size
        })
      }
      truck.createdAt = msg.createdAt;
      truck.lat = msg.data.lat;
      truck.lng = msg.data.lng;

      truck.marker.setPosition(position);
    }

    wasEmpty && this.updateBounds();
  }

  private _initSocket() {
    this.socket = connectSocket("/mysocket");

    this.socket.on('position', (msg) => {
      console.debug("msg", msg);
      this.updateTrucks(msg);
    });

    this.socket.connect();
  }

  async mounted() {
    this.initFilters()

    if(this.$route.query.id){
      this.advFilters.user = await usersService.getById(+this.$route.query.id)
    }
    this.statusIconMap = {
      [TicketStatusEnum.DUMPED]: this.$refs.truck_dumped,
      [TicketStatusEnum.WAITING_DUMP]: this.$refs.truck_waiting_dump,
      [TicketStatusEnum.WAITING_LOAD]: this.$refs.truck_waiting_load,
      [TicketStatusEnum.LOADED]: this.$refs.truck_loaded
    }
    this._initSocket();
    this._buildMap((this.$refs.mapEl as HTMLElement));
  }

  beforeUnmount() {
    this.socket.disconnect();
  }
}
