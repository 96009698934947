
import { Options, Vue } from "vue-class-component";
import {Model, Prop} from "vue-property-decorator";
import {TicketStatusEnum} from "@/model/enums/TicketStatusEnum";
import {CompanyAutocomplete, ShiftAutocomplete, UserDriverAutocomplete} from "@components";
import {authStore} from "@/modules/auth/store";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {User} from "@/model/api/User";
import {Company} from "@/model/api/Company";
import {Shift} from "@/model/api/Shift";
import {usersService} from "@services/users.service";
import {companiesService} from "@services/companies.service";
import {shiftsService} from "@services/shifts.service";

@Options({
  components: {
    UserDriverAutocomplete,
    CompanyAutocomplete,
    ShiftAutocomplete
  }
})
export default class extends Vue {

  cachedTrucks = {
    shifts: [],
    brokers: [],
    users: []
  }

  @Prop() readonly trucks: any[] = []
  drivers: User[] = []
  filteredDrivers: User[] = []
  brokers: Company[] = []
  filteredBrokers: Company[] = []
  wrs: Shift[] = []
  filteredWrs: Shift[] = []

  @Model("filters")
  advFilters: any;


  onlyInLeft(left, right, compareFunction) {
    return left.filter(leftValue =>
        !right.some(rightValue =>
            compareFunction(leftValue, rightValue)));
  }

  async searchUser(event) {

    const isSameUser = (a, b) => a.id === b.id;

    const onlyInA = this.onlyInLeft(this.trucks, this.cachedTrucks.users, isSameUser);

    if(onlyInA.length){
      const promises = []
      this.trucks.forEach((truck: any) => {
        promises.push(usersService.getBasicById(truck.id))
      })
      this.drivers = await Promise.all(promises)
    }

    this.filteredDrivers = this.drivers.filter((driver) => {
      if(this.advFilters.broker && driver.broker_id !== this.advFilters.broker.id){
        return false
      }
      return driver.name.toLowerCase().includes(event.query.toLowerCase())
    });

    this.cachedTrucks.users = [...this.trucks]
  }

  async searchBroker(event){
    const isSameBroker = (a, b) => a.broker_id === b.broker_id;

    const onlyInA = this.onlyInLeft(this.trucks, this.cachedTrucks.brokers, isSameBroker);

    if(onlyInA.length){
      const promises = []
      this.trucks.forEach((truck: any) => {
        promises.push(companiesService.getById(truck.broker_id))
      })
      this.brokers = await Promise.all(promises)
    }

    this.filteredBrokers = this.brokers.filter((broker) => {
      return broker.business_name.toLowerCase().includes(event.query.toLowerCase())
    });

    this.cachedTrucks.brokers = [...this.trucks]
  }

  async searchWRS(event){
    const isSameBroker = (a, b) => a.shift_id === b.shift_id;

    const onlyInA = this.onlyInLeft(this.trucks, this.cachedTrucks.brokers, isSameBroker);

    if(onlyInA.length){
      const promises = []
      this.trucks.forEach((truck: any) => {
        promises.push(shiftsService.getById(truck.shift_id))
      })
      this.wrs = await Promise.all(promises)
    }

    this.filteredWrs = this.wrs.filter((wrs) => {
      return wrs.code.toLowerCase().includes(event.query.toLowerCase())
    });

    this.cachedTrucks.shifts = [...this.trucks]
  }

  getTitle({ zone_load, zone_dump}: Shift) {
    const [
      {name: loadName},
      {name: dumpName}
    ] = [zone_load, zone_dump];

    return `LoadSite: ${loadName}\nDumpSite: ${dumpName}`
  }

  get canSee() {
    return authStore.getters.me.role !== UserRoleEnum.BROKER
  }

  getField(user: User) {
    return `${user.name} ${user.surname}`;
  }

  selectedLabel(item: Shift) {
    return `#${item.id} - ${item.code}`;
  }

  get ticketStatuses() {
    return [
      {
        label: "Select Status",
        value: null
      },
      {
        label: this.$t(`ticket.statuses.WAITING_LOAD`),
        value: TicketStatusEnum.WAITING_LOAD
      },
      {
        label: this.$t(`ticket.statuses.LOADED`),
        value: TicketStatusEnum.LOADED
      },
      {
        label: this.$t(`ticket.statuses.WAITING_DUMP`),
        value: TicketStatusEnum.WAITING_DUMP
      },
      {
        label: this.$t(`ticket.statuses.DUMPED`),
        value: TicketStatusEnum.DUMPED
      },
    ];
  }

  onSelectedUser() {
    this.emitFilters();
  }

  onSelectedShift() {
    this.emitFilters();
  }

  onSelectedStatus(){
    this.emitFilters();
  }

  onBrokerSelected() {
    if(this.advFilters.user){
      this.advFilters.user = null
    }
    this.emitFilters();
  }

  emitFilters() {
    this.$emit("change", this.advFilters);
  }

}
